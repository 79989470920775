// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-landing-page-wantcard-js": () => import("/opt/build/repo/src/Templates/LandingPage/Wantcard.js" /* webpackChunkName: "component---src-templates-landing-page-wantcard-js" */),
  "component---src-templates-product-detail-page-wantcard-js": () => import("/opt/build/repo/src/Templates/ProductDetailPage/Wantcard.js" /* webpackChunkName: "component---src-templates-product-detail-page-wantcard-js" */),
  "component---src-templates-article-detail-page-index-js": () => import("/opt/build/repo/src/Templates/ArticleDetailPage/index.js" /* webpackChunkName: "component---src-templates-article-detail-page-index-js" */),
  "component---src-templates-store-detail-page-index-js": () => import("/opt/build/repo/src/Templates/StoreDetailPage/index.js" /* webpackChunkName: "component---src-templates-store-detail-page-index-js" */),
  "component---src-templates-store-listing-page-index-js": () => import("/opt/build/repo/src/Templates/StoreListingPage/index.js" /* webpackChunkName: "component---src-templates-store-listing-page-index-js" */),
  "component---src-templates-product-listing-page-index-js": () => import("/opt/build/repo/src/Templates/ProductListingPage/index.js" /* webpackChunkName: "component---src-templates-product-listing-page-index-js" */),
  "component---src-templates-article-listing-page-index-js": () => import("/opt/build/repo/src/Templates/ArticleListingPage/index.js" /* webpackChunkName: "component---src-templates-article-listing-page-index-js" */),
  "component---src-templates-order-detail-page-index-js": () => import("/opt/build/repo/src/Templates/OrderDetailPage/index.js" /* webpackChunkName: "component---src-templates-order-detail-page-index-js" */),
  "component---src-templates-profile-page-wantcard-js": () => import("/opt/build/repo/src/Templates/ProfilePage/Wantcard.js" /* webpackChunkName: "component---src-templates-profile-page-wantcard-js" */),
  "component---src-templates-cart-page-index-js": () => import("/opt/build/repo/src/Templates/CartPage/index.js" /* webpackChunkName: "component---src-templates-cart-page-index-js" */),
  "component---src-templates-social-login-result-page-index-js": () => import("/opt/build/repo/src/Templates/SocialLoginResultPage/index.js" /* webpackChunkName: "component---src-templates-social-login-result-page-index-js" */),
  "component---src-templates-service-terms-page-index-js": () => import("/opt/build/repo/src/Templates/ServiceTermsPage/index.js" /* webpackChunkName: "component---src-templates-service-terms-page-index-js" */),
  "component---src-templates-privacy-policy-page-index-js": () => import("/opt/build/repo/src/Templates/PrivacyPolicyPage/index.js" /* webpackChunkName: "component---src-templates-privacy-policy-page-index-js" */),
  "component---src-templates-faq-page-index-js": () => import("/opt/build/repo/src/Templates/FaqPage/index.js" /* webpackChunkName: "component---src-templates-faq-page-index-js" */),
  "component---src-templates-instruction-page-index-js": () => import("/opt/build/repo/src/Templates/InstructionPage/index.js" /* webpackChunkName: "component---src-templates-instruction-page-index-js" */),
  "component---src-templates-blog-detail-page-index-js": () => import("/opt/build/repo/src/Templates/BlogDetailPage/index.js" /* webpackChunkName: "component---src-templates-blog-detail-page-index-js" */),
  "component---src-templates-blog-listing-page-index-js": () => import("/opt/build/repo/src/Templates/BlogListingPage/index.js" /* webpackChunkName: "component---src-templates-blog-listing-page-index-js" */)
}

